<template>
  <a-spin :spinning="loading" :style="backCss" class="box" @mousedown="handleMouseDown">
    <div
      class="mask"
      v-show="is_show_mask"
      :style="'width:' + mask_width + 'left:' + mask_left + 'height:' + mask_height + 'top:' + mask_top"
    ></div>
    <FullCalendar class="fullCalendar" ref="fullCalendar" :options="calendarOptions">
      <template v-slot:eventContent="arg">
        <div
          v-if="arg.event.title && arg.event.title != 'dropDiv'"
          :ref="arg.event.extendedProps.record.id"
          :style="'width:100%;margin-right:5px;margin-bottom:5px'"
        >
          <div
            v-if="arg.event.title"
            :style="
              'display: flex; justify-content: space-between;width:100%;' + getBack(arg.event.extendedProps.record)
            "
          >
            <div style="display: flex;overflow:hidden;width: 92%;position:relative">
              <a-checkbox
                @click.stop
                :ref="arg.event.extendedProps.record.id + '-select'"
                :id="arg.event.extendedProps.record.id + '-select'"
                @change="
                  e => {
                    dropSelectChange(e, arg.event.extendedProps.record)
                  }
                "
                v-if="arg.event.extendedProps.record.taskStatus != '已完成'"
              ></a-checkbox>
              <span
                style="margin-left:5px;overflow:hidden;white-space: nowrap;text-overflow: ellipsis;-o-text-overflow:ellipsis;max-width:120px;display:inline-block"
              >
                {{
                  arg.event.extendedProps.record.productSequenceNo
                    ? arg.event.extendedProps.record.productSequenceNo + '-'
                    : ''
                }}{{ arg.event.title.split('-')[0] }}
              </span>
              <span style="min-width:100px;display:inline-block">
                {{
                  '-' +
                    arg.event.title.split('-')[1] +
                    '-' +
                    arg.event.title.split('-')[2] +
                    '-' +
                    arg.event.title.split('-')[3] +
                    (arg.event.title.split('-')[4] ? '-' + arg.event.title.split('-')[4] : '')
                }}
              </span>
            </div>
            <actions-tasks
              :isChapter="true"
              :isMyTask="false"
              :isCalendar="true"
              v-if="arg.event.extendedProps.record && 'customEvent' != arg.event.extendedProps.record.type"
              :propsParams="arg.event.extendedProps.record"
            ></actions-tasks>
            <div
              class="reject-icon"
              v-if="
                arg.event.extendedProps.record.taskStatus == '驳回' &&
                  arg.event.extendedProps.record.rejectTask &&
                  arg.event.extendedProps.record.rejectTask.taskName == '甲方反馈'
              "
            ></div>
          </div>
        </div>
        <div v-else-if="arg.event.title == 'dropDiv'" class="drop-list"></div>
      </template>
    </FullCalendar>
    <a-modal
      width="900px"
      :visible="needScheduleTask"
      @ok="handleEditdateOk"
      @cancel="handleAddEventCancel"
      title="自动排期"
    >
      <div style="display:flex;align-items: center;">
        <span>自动排期：</span>
        <div style="display:flex;height: 40px;align-items: center;justify-content: space-between;">
          <a-radio-group v-model="taskRadioValue">
            <a-radio value="1">是</a-radio>
            <a-radio value="2">否</a-radio>
          </a-radio-group>
          <a-checkbox-group
            v-if="taskRadioValue == '1'"
            style="display:flex;flex: 1;justify-content: space-around;margin-left:15px"
            v-model="taskCheckedList"
            :options="plainOptions"
          />
        </div>
      </div>
      <div style="margin-top:20px;" v-if="taskRadioValue == '2'">
        <chapter-table ref="chapterTable" :scheduleTasks="scheduleTasks" />
      </div>
      <template slot="footer">
        <a-button key="back" @click="handleAddEventCancel">
          取消
        </a-button>
        <a-button key="submit" type="primary" :loading="btnLoading" @click="handleEditdateOk">
          确定
        </a-button>
      </template>
    </a-modal>
    <a-icon type="check-circle" @click="save" theme="filled" />
  </a-spin>
</template>

<script>
import ActionsTasks from '@/views/system/modules/mytasks/actionsTasks'
import chapterTable from './chapter-table.vue'
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import timeGridPlugin from '@fullcalendar/timegrid'
import { getAction, putAction, postAction, deleteAction } from '@/api/manage'
function handleArrayParams(params) {
  return Object.fromEntries(
    Object.entries(params).flatMap(([k, v]) =>
      Array.isArray(v) ? v.map((value, index) => [`${k}[${index}]`, value]) : [[k, v]]
    )
  )
}
export default {
  components: {
    FullCalendar,
    ActionsTasks,
    chapterTable
  },
  props: {
    tasks: {
      type: Array,
      default: () => []
    },
    chapters: {
      type: Array,
      default: () => []
    },
    checkedList: {
      type: Array,
      default: () => []
    },
    dateType: {
      type: String,
      default: '1'
    },
    autoCalculate: {
      type: String,
      default: '1'
    }
  },
  data() {
    return {
      btnLoading: false,
      loading: false,
      taskRadioValue: '1',
      calendarOptions: {
        plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin], // 需要用哪个插件引入后放到这个数组里
        initialDate: new Date(), // 日历第一次加载时显示的初始日期。可以解析为Date的任何职包括ISO8601日期字符串，例如"2014-02-01"。 // 日历加载时的初始视图，默认值为'dayGridMonth'，可以为任何可用视图的值，如例如'dayGridWeek'，'timeGridDay'，'listWeek'
        initialView: 'dayGridMonth', // 日历加载时的初始视图，默认值为'dayGridMonth'，可以为任何可用视图的值，如例如'dayGridWeek'，'timeGridDay'，'listWeek' // 日历加载时的初始视图，默认值为'dayGridMonth'，可以为任何可用视图的值，如例如'dayGridWeek'，'timeGridDay'，'listWeek'
        /* initialView: this.isAccountCenter ? 'dayGridWeek' : 'dayGridMonth', */ locale: 'zh-cn', // 设置日历的语言，中文为 “zh-cn”
        firstDay: '1', // 设置每周的第一天，默认值取决于当前语言环境，该值为代表星期几的数字，且值必须为整数，星期日=0
        weekNumberCalculation: 'ISO', // 指定"ISO"结果为ISO8601周数。指定"ISO"将firstDay的默认值更改为1（Monday）
        /* editable: true, */
        selectable: true,
        unselectAuto: false,
        eventOrder: 'eventOrder',
        height: 'auto', //dayRender已废弃，使用views替换
        buttonText: {
          // 文本将显示在headerToolbar / footerToolbar的按钮上。不支持HTML注入。所有特殊字符将被转义。
          today: '今天',
          month: '月',
          week: '周',
          day: '天'
        },
        headerToolbar: {
          // 在日历顶部定义按钮和标题。将headerToolbar选项设置为false不会显示任何标题工具栏。可以为对象提供属性start/center/end或left/center/right。这些属性包含带有逗号/空格分隔值的字符串。用逗号分隔的值将相邻显示。用空格分隔的值之间会显示一个很小的间隙。
          right: 'today,prev,next',
          center: 'title',
          left: 'dayGridMonth,dayGridWeek,dayGridDay'
        },
        eventTimeFormat: {
          // 在每个事件上显示的时间的格式
          hour: 'numeric',
          minute: '2-digit',
          hour12: false
        },
        events: this.fetchCalendarData,
        /* eventRender: (event,element)=>{this.calendarRender(event,element)}, */
        customButtons: {
          // 定义可在headerToolbar / footerToolbar中使用的自定义按钮
          today: {
            text: '今天', // 按钮的展示文本
            click: this.todayClick // 点击按钮触发的事件，这里要注意的是当按钮绑定了事件之后该按钮原本自带的事件将会失效
          },
          next: {
            click: this.nextClick
          },
          prev: {
            click: this.prevClick
          },
          dayGridDay: {
            text: '天',
            click: this.dayClick
          },
          dayGridWeek: {
            text: '周',
            click: this.weekClick
          },
          dayGridMonth: {
            text: '月',
            click: this.monthClick
          }
        } /* dateClick: this.handleDateClick, */ /* eventMouseEnter: this.handleMouseEnter, */ /* eventMouseLeave: this.handleMouseLeave, */, // 点击事件时，触发该回调 // 鼠标悬停在事件上时，触发该回调 // 鼠标移除时，触发该回调 // 当用户单击日期或时间时,触发该回调，触发此回调，您必须加载interaction插件
        /* eventClick: this.handleEventClick, */ eventDrop: this.handleEventDrop, //是否可拖拽
        select: this.selectDaysChange,
        eventStartEditable: true,
        eventDragStart: this.eventDragStart, //开始拖拽
        eventDragStop: this.eventDragStop //结束拖拽
        // eventContent: this.eventContentCallback,
      },
      start_x: 0,
      start_y: 0,
      end_x: 0,
      end_y: 0,
      select_list: [],
      is_show_mask: true,
      box_screen_left: 0,
      box_screen_top: 0,
      taskIds: [],
      isDrag: false,
      dropTimeEnd: '',
      scheduleTasks: [],
      dropTimeStart: '',
      needScheduleTask: false,
      plainOptions: ['可工作日', '阶段耗时', '可安排数量'],
      taskCheckedList: ['可工作日', '阶段耗时', '可安排数量'],
      moveData: {}
    }
  },
  watch: {
    taskRadioValue() {
      if (this.taskRadioValue == '2') {
        this.$nextTick(() => {
          if (this.$refs.chapterTable) this.$refs.chapterTable.getTableData()
        })
      }
    }
  },
  computed: {
    backCss() {
      this.color = this.$store.state.app.color
      return {
        '--theme-color': this.color,
        'padding-right': '43px'
      }
    },
    mask_width() {
      return `${Math.abs(this.end_x - this.start_x)}px;`
    },
    mask_top() {
      return `${Math.min(this.start_y, this.end_y) - this.box_screen_top}px;`
    },
    mask_left() {
      return `${Math.min(this.start_x, this.end_x) - this.box_screen_left}px;`
    },
    mask_height() {
      return `${Math.abs(this.end_y - this.start_y)}px;`
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (this.$refs.fullCalendar) {
        this.calendarApi = this.$refs.fullCalendar.getApi()
        const dom_box = document.querySelector('.box')
      }
    })
  },
  methods: {
    async save() {
      this.loading = true
      let c = JSON.parse(JSON.stringify(this.chapters))
      c.forEach(item => {
        item.tasks?.forEach(item => {
          if (item.taskStatus == '驳回') {
            item.taskStatus = 'REJECTING'
          } else if (item.taskStatus == '可开始') {
            item.taskStatus = 'STARTED'
          } else if (item.taskStatus == '等待') {
            item.taskStatus = 'WAITING'
          } else if (item.taskStatus == '已完成') {
            item.taskStatus = 'FINISHED'
          }
        })
      })
      delete c.taskStatus
      let data = {
        productId: this.$route.params.id,
        isAuto: this.autoCalculate == '1', //是否自动排期
        isWorkDay: this.taskCheckedList.some(item => item == '可工作日'), // 是否考虑可工作日
        isInterval: this.taskCheckedList.some(item => item == '阶段耗时'), // 是否考虑任务耗时
        isScheduleCount: this.taskCheckedList.some(item => item == '可安排数量'), // 是否考虑任务排满
        isDraftBegin: this.dateType == '1', // 是否从给稿开始排
        chapters: c,
        alertTasks: this.allList.map(item => item.record)
      }
      const res = await postAction('/production/create_chapter', data)
      this.loading = false
      if (res.code == 200) {
        this.$message.success('保存成功')
        this.select_list = []
        this.$emit('closeCalendar')
      } else {
        this.$message.error(res.msg || res.message)
      }
    },
    reloadData() {
      this.calendarApi.removeAllEvents()
      this.calendarApi.addEventSource(this.allList)
      this.select_list = []
      this.taskIds = []
      this.$forceUpdate()
    },
    getNewDate(str) {
      var strArray = str.split(' ')
      var strDate = strArray[0].split('-')
      var strTime = strArray[1].split(':')
      var a = new Date(strDate[0], strDate[1] - parseInt(1), strDate[2], strTime[0], strTime[1], strTime[2])
      return a
    },
    async handleEditdateOk() {
      if (this.taskRadioValue == '2') {
        if (!this.$refs.chapterTable) return
        let arr = []
        this.$refs.chapterTable.tableData?.forEach(item => {
          arr = [...arr, ...item.children]
        })
        arr.forEach(a => {
          if (a.deadlineNew.length == 16) {
            a.deadlineNew = a.deadlineNew + ':59'
          }
        })
        console.log(arr[0].deadlineNew, arr[0].deadlineNew.length, this.dropTimeStart)

        /* if (
          arr.length &&
          this.getNewDate(arr[0].deadlineNew) <this.getNewDate(this.dropTimeStart + ' 23:59:59')
        ) {
          this.$message.error('无法将后面阶段的任务拖动至前面阶段截止日前，请选择自动排期或调整其他任务后再做更改')
          return
        } */
        let stageList = []
        this.allList.forEach(d => {
          if (d.record.chapterOrder == arr[0].chapterOrder) {
            stageList.push(d.record)
          }
        })
        if (arr[0].taskId != stageList[0].taskId) {
          let volid2 = false
          let diffIdx = stageList.findIndex(s => s.taskId == arr[0].taskId)
          if (arr[0] && this.getNewDate(arr[0].deadlineNew) < this.getNewDate(stageList[diffIdx - 1].deadline)) {
            volid2 = true
          }
          /*           if (volid2) {
            this.$message.error('无法将后面阶段的任务拖动至前面阶段截止日前，请选择自动排期或调整其他任务后再做更改')
            return
          } */
        }
        let volid1 = false
        arr.forEach((a, aIdx) => {
          if (arr[aIdx - 1] && this.getNewDate(a.deadlineNew) < this.getNewDate(arr[aIdx - 1].deadlineNew)) {
            volid1 = true
          }
        })
        /*         if (volid1) {
          this.$message.error('无法将后面阶段的任务拖动至前面阶段截止日前，请选择自动排期或调整其他任务后再做更改')
          return
        } */
        let volid = false
        arr.forEach((a, aIdx) => {
          if (arr[aIdx + 1] && this.getNewDate(a.deadlineNew) > this.getNewDate(arr[aIdx + 1].deadlineNew)) {
            volid = true
          }
        })
        /*         if (volid) {
          this.$message.error('无法将前面阶段的任务拖动至后面阶段截止日后，请选择自动排期或调整其他任务后再做更改')
          return
        } */
        this.btnLoading = true
        arr.forEach(item => {
          this.allList.forEach((i, index) => {
            if (i.record.stageId == item.stageId && i.record.chapterOrder == item.chapterOrder) {
              if (item.taskStatus == 'REJECTING') {
                item.taskStatus = '驳回'
              } else if (item.taskStatus == 'STARTED') {
                item.taskStatus = '可开始'
              } else if (item.taskStatus == 'WAITING') {
                item.taskStatus = '等待'
              } else if (item.taskStatus == 'FINISHED') {
                item.taskStatus = '已完成'
              }
              item.productSequenceNo = this.chapters[0].tasks[0].productSequenceNo
              item.productionId = this.chapters[0].tasks[0].productionId
              item.productionName = this.chapters[0].tasks[0].productionName
              item.productName = this.chapters[0].tasks[0].productName
              item.deadline = item.deadlineNew
              i.title =
                item.productName +
                '-' +
                parseFloat((item.chapterOrder - 0).toFixed(1)) +
                '-' +
                item.stageName +
                '-' +
                item.user.userName
              i.start = item.deadlineNew
              i.record = item
              i.record.id = i.id
              i.eventOrder = index + 1
            }
            if (i.taskStatus == 'REJECTING') {
              i.taskStatus = '驳回'
            } else if (i.taskStatus == 'STARTED') {
              i.taskStatus = '可开始'
            } else if (i.taskStatus == 'WAITING') {
              i.taskStatus = '等待'
            } else if (item.taskStatus == 'FINISHED') {
              i.taskStatus = '已完成'
            }
          })
        })
        let dateArr = this.allList
          .filter(item => item.title != 'dropDiv')
          .map(one => {
            return one.start.split(' ')[0] + ' 00:00:00'
          })
        dateArr = [...new Set(dateArr)]
        dateArr.forEach(item => {
          if (this.allList.every(i => i.start != item)) {
            this.allList.push({
              id: item,
              title: 'dropDiv',
              start: item,
              eventOrder: 0,
              allDay: true,
              editable: true,
              color: 'white',
              textColor: 'gray',
              record: {}
            })
          }
        })
        this.needScheduleTask = false
        this.btnLoading = false
        this.taskRadioValue = '1'
        this.reloadData()
        return
      } else {
        let moveTasks = []
        this.btnLoading = true
        if (this.taskIds.length) {
          moveTasks = this.allList
            .filter(item => {
              return this.taskIds.some(i => i == item.record.id)
            })
            .map(item => item.record)
        } else {
          moveTasks = this.allList
            .filter(item => {
              return this.moveData.taskId == item.record.id
            })
            .map(item => item.record)
        }
        let chapters = JSON.parse(JSON.stringify(this.chapters))
        chapters.forEach(chapter => {
          chapter.tasks?.forEach(item => {
            if (item.taskStatus == '驳回') {
              item.taskStatus = 'REJECTING'
            } else if (item.taskStatus == '可开始') {
              item.taskStatus = 'STARTED'
            } else if (item.taskStatus == '等待') {
              item.taskStatus = 'WAITING'
            } else if (item.taskStatus == '已完成') {
              item.taskStatus = 'FINISHED'
            }
          })
        })
        moveTasks.forEach(item => {
          if (item.taskStatus == '驳回') {
            item.taskStatus = 'REJECTING'
          } else if (item.taskStatus == '可开始') {
            item.taskStatus = 'STARTED'
          } else if (item.taskStatus == '等待') {
            item.taskStatus = 'WAITING'
          } else if (item.taskStatus == '已完成') {
            item.taskStatus = 'FINISHED'
          }
        })
        const res = await postAction('/taskSchedule/createChapter/moveTaskConfirm', {
          productId: this.$route.params.id,
          moveTasks: moveTasks,
          isAuto: this.autoCalculate == '1', //是否自动排期
          isWorkDay: this.taskCheckedList.some(item => item == '可工作日'), // 是否考虑可工作日
          isInterval: this.taskCheckedList.some(item => item == '阶段耗时'), // 是否考虑任务耗时
          isScheduleCount: this.taskCheckedList.some(item => item == '可安排数量'), // 是否考虑任务排满
          isDraftBegin: this.dateType == '1', // 是否从给稿开始排
          targetDate: this.dropTimeEnd,
          chapters
        })
        this.btnLoading = false
        if (res.code == 200) {
          res.data.alertTasks?.forEach(item => {
            this.allList.forEach((i, index) => {
              if (i.record.stageId == item.stageId && i.record.chapterOrder == item.chapterOrder) {
                if (item.taskStatus == 'REJECTING') {
                  item.taskStatus = '驳回'
                } else if (item.taskStatus == 'STARTED') {
                  item.taskStatus = '可开始'
                } else if (item.taskStatus == 'WAITING') {
                  item.taskStatus = '等待'
                } else if (item.taskStatus == 'FINISHED') {
                  item.taskStatus = '已完成'
                }
                item.productSequenceNo = this.chapters[0].tasks[0].productSequenceNo
                item.productionId = this.chapters[0].tasks[0].productionId
                item.productionName = this.chapters[0].tasks[0].productionName
                item.productName = this.chapters[0].tasks[0].productName
                i.title =
                  item.productName +
                  '-' +
                  parseFloat((item.chapterOrder - 0).toFixed(1)) +
                  '-' +
                  item.stageName +
                  '-' +
                  item.user.userName
                i.start = item.deadline
                i.record = { ...item }
                i.eventOrder = index + 1
                i.record.id = i.id
              }
            })
          })
          let dateArr = this.allList
            .filter(item => item.title != 'dropDiv')
            .map(one => {
              return one.start.split(' ')[0] + ' 00:00:00'
            })
          dateArr = [...new Set(dateArr)]
          dateArr.forEach(item => {
            if (this.allList.every(i => i.start != item)) {
              this.allList.push({
                id: item,
                title: 'dropDiv',
                start: item,
                eventOrder: 0,
                allDay: true,
                editable: true,
                color: 'white',
                textColor: 'gray',
                record: {}
              })
            }
          })
          this.needScheduleTask = false
          this.taskRadioValue = '1'
          this.reloadData()
        } else {
          this.$message.error(res.msg || res.message)
        }
        return
      }
    },
    handleAddEventCancel() {
      this.needScheduleTask = false
      this.taskRadioValue = '1'
      this.reloadData()
    },
    eventDragStart(e) {
      this.isDrag = true
      Array.from(document.getElementsByClassName('drop-list')).forEach(item => {
        if (item.childNodes.length) {
          e.el.childNodes[0].appendChild(item)
        }
      })
    },
    eventDragStop(e) {
      this.isDrag = false
      this.dropTimeStart =
        e.el.parentNode.parentNode.parentNode.parentNode.dataset.date /* this.insertStr(
        this.insertStr(e.el.parentNode.parentNode.parentNode.parentNode.dataset.date, 4, '-'),
        7,
        '-'
      ) */
    },
    insertStr(source, start, newStr) {
      return source.slice(0, start) + newStr + source.slice(start)
    },
    dateChange(num = 1, date = false) {
      if (!date) {
        date = new Date() //没有传入值时,默认是当前日期
        date = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()
      }
      date += ' 00:00:00' //设置为当天凌晨12点
      date = Date.parse(new Date(date)) / 1000 //转换为时间戳
      date += 86400 * num //修改后的时间戳
      var newDate = new Date(parseInt(date) * 1000) //转换为时间
      var Y = newDate.getFullYear() + ''
      var M = (newDate.getMonth() + 1 < 10 ? '0' + (newDate.getMonth() + 1) : newDate.getMonth() + 1) + ''
      var D = (newDate.getDate() < 10 ? '0' + newDate.getDate() : newDate.getDate()) + ''
      return Y + '-' + M + '-' + D
    },
    async handleEventDrop(eventInfo) {
      this.dropTimeEnd = this.dateChange(eventInfo.delta.days, this.dropTimeStart)
      if (this.taskIds.length) {
        let moveTasks = this.allList
          .filter(item => {
            return this.taskIds.some(i => i == item.record.id)
          })
          .map(item => item.record)
        let chapters = JSON.parse(JSON.stringify(this.chapters))
        chapters.forEach(chapter => {
          chapter.tasks?.forEach(item => {
            if (item.taskStatus == '驳回') {
              item.taskStatus = 'REJECTING'
            } else if (item.taskStatus == '可开始') {
              item.taskStatus = 'STARTED'
            } else if (item.taskStatus == '等待') {
              item.taskStatus = 'WAITING'
            } else if (item.taskStatus == '已完成') {
              item.taskStatus = 'FINISHED'
            }
          })
        })
        moveTasks.forEach(item => {
          if (item.taskStatus == '驳回') {
            item.taskStatus = 'REJECTING'
          } else if (item.taskStatus == '可开始') {
            item.taskStatus = 'STARTED'
          } else if (item.taskStatus == '等待') {
            item.taskStatus = 'WAITING'
          } else if (item.taskStatus == '已完成') {
            item.taskStatus = 'FINISHED'
          }
        })
        const rest = await postAction('/taskSchedule/createChapter/moveTask', {
          productId: this.$route.params.id,
          moveTasks: moveTasks,
          isAuto: this.autoCalculate == '1', //是否自动排期
          isWorkDay: this.taskCheckedList.some(item => item == '可工作日'), // 是否考虑可工作日
          isInterval: this.taskCheckedList.some(item => item == '阶段耗时'), // 是否考虑任务耗时
          isScheduleCount: this.taskCheckedList.some(item => item == '可安排数量'), // 是否考虑任务排满
          isDraftBegin: this.dateType == '1', // 是否从给稿开始排
          targetDate: this.dropTimeEnd,
          chapters
        })
        try {
          if (rest.code != 200) {
            return this.$message.error(rest.msg)
          }
          this.scheduleTasks =
            rest.data.alertTasks?.map(item => {
              item.deadlineNew = item.deadline
              return item
            }) || []
          let taskArr = []
          this.scheduleTasks = [...this.scheduleTasks, ...taskArr]
          console.log(this.scheduleTasks, rest.data, 123)
          if (this.scheduleTasks.length) {
            this.needScheduleTask = true
          } else {
            moveTasks.forEach(item => {
              this.allList.forEach(i => {
                if (i.id == item.id) {
                  i.start = this.dropTimeEnd
                  i.record.deadline = this.dropTimeEnd
                }
              })
            })
            this.calendarApi.removeAllEvents()
            this.calendarApi.addEventSource(this.allList)
            this.select_list = []
            this.$forceUpdate()
          }
        } catch (err) {
          console.error(err)
        }
        return
      }
      this.moveData = { taskId: eventInfo.event.id, days: eventInfo.delta.days }
      let moveTasks = this.allList
        .filter(item => {
          return eventInfo.event.id == item.id
        })
        .map(item => item.record)
      let chapters = JSON.parse(JSON.stringify(this.chapters))
      chapters.forEach(chapter => {
        chapter.tasks?.forEach(item => {
          if (item.taskStatus == '驳回') {
            item.taskStatus = 'REJECTING'
          } else if (item.taskStatus == '可开始') {
            item.taskStatus = 'STARTED'
          } else if (item.taskStatus == '等待') {
            item.taskStatus = 'WAITING'
          } else if (item.taskStatus == '已完成') {
            item.taskStatus = 'FINISHED'
          }
        })
      })
      moveTasks.forEach(item => {
        if (item.taskStatus == '驳回') {
          item.taskStatus = 'REJECTING'
        } else if (item.taskStatus == '可开始') {
          item.taskStatus = 'STARTED'
        } else if (item.taskStatus == '等待') {
          item.taskStatus = 'WAITING'
        } else if (item.taskStatus == '已完成') {
          item.taskStatus = 'FINISHED'
        }
      })
      console.log(
        {
          productId: this.$route.params.id,
          moveTasks: moveTasks,
          isAuto: this.autoCalculate == '1', //是否自动排期
          isWorkDay: this.taskCheckedList.some(item => item == '可工作日'), // 是否考虑可工作日
          isInterval: this.taskCheckedList.some(item => item == '阶段耗时'), // 是否考虑任务耗时
          isScheduleCount: this.taskCheckedList.some(item => item == '可安排数量'), // 是否考虑任务排满
          isDraftBegin: this.dateType == '1', // 是否从给稿开始排
          targetDate: this.dropTimeEnd,
          chapters
        },
        123
      )
      const rest = await postAction('/taskSchedule/createChapter/moveTask', {
        productId: this.$route.params.id,
        moveTasks: moveTasks,
        isAuto: this.autoCalculate == '1', //是否自动排期
        isWorkDay: this.taskCheckedList.some(item => item == '可工作日'), // 是否考虑可工作日
        isInterval: this.taskCheckedList.some(item => item == '阶段耗时'), // 是否考虑任务耗时
        isScheduleCount: this.taskCheckedList.some(item => item == '可安排数量'), // 是否考虑任务排满
        isDraftBegin: this.dateType == '1', // 是否从给稿开始排
        targetDate: this.dropTimeEnd,
        chapters
      })
      try {
        if (rest.code != 200) {
          return this.$message.error(rest.msg)
        }
        this.scheduleTasks =
          rest.data.alertTasks?.map(item => {
            item.deadlineNew = item.deadline
            return item
          }) || []
        let taskArr = []
        this.scheduleTasks = [...this.scheduleTasks, ...taskArr]
        this.scheduleTasks.forEach(s => {
          moveTasks.forEach(m => {
            if (
              (m.taskId && s.taskId == m.taskId) ||
              (!m.taskId && s.stageId == m.stageId && s.chapterOrder == m.chapterOrder)
            ) {
              s.deadlineNew = this.dropTimeEnd + ' 23:59:59'
            }
          })
        })
        if (this.scheduleTasks.length) {
          this.needScheduleTask = true
        } else {
          moveTasks.forEach(item => {
            this.allList.forEach(i => {
              if (i.id == item.id) {
                i.start = this.dropTimeEnd
                i.record.deadline = this.dropTimeEnd
              }
            })
          })
          this.calendarApi.removeAllEvents()
          this.calendarApi.addEventSource(this.allList)
          this.select_list = []
          this.$forceUpdate()
        }
      } catch (err) {
        console.error(err)
      }
      return
    },
    handleMouseDown(event) {
      if (event.target.tagName === 'SPAN') return false

      this.is_show_mask = true
      this.start_x = event.clientX
      this.start_y = event.clientY
      this.end_x = event.clientX
      this.end_y = event.clientY
      document.body.addEventListener('mousemove', this.handleMouseMove)
      document.body.addEventListener('mouseup', this.handleMouseUp)
    },
    handleMouseMove(event) {
      if (this.isDrag) {
        this.start_x = 0
        this.start_y = 0
        this.end_x = 0
        this.end_y = 0
        return
      }
      this.end_x = event.clientX
      this.end_y = event.clientY
    },
    handleMouseUp() {
      document.body.removeEventListener('mousemove', this.handleMouseMove)
      document.body.removeEventListener('mouseup', this.handleMouseUp)
      this.is_show_mask = false
      this.handleDomSelect()
      this.resSetXY()
    },
    resSetXY() {
      this.start_x = 0
      this.start_y = 0
      this.end_x = 0
      this.end_y = 0
    },
    handleDomSelect() {
      const dom_mask = window.document.querySelector('.mask')
      const rect_select = dom_mask.getClientRects()[0]

      const add_list = []
      const del_list = []
      this.$refs.fullCalendar.$el.querySelectorAll('.ant-checkbox-wrapper').forEach((node, index) => {
        if (!node.getClientRects()[0]) return
        const rects = node.getClientRects()[0]
        if (this.collide(rects, rect_select) === true) {
          let arr = []
          arr = this.allList.filter(item => {
            return item.record.taskStatus != '已完成' && item.title != 'dropDiv'
          })
          let nodeId = node.getElementsByClassName('ant-checkbox-input')[0].id.split('-')[0]
          if (
            nodeId &&
            this.select_list.includes(
              arr.find(item => {
                return item.id == nodeId
              }).id
            )
          ) {
            del_list.push(
              arr.find(item => {
                return item.id == nodeId
              }).id
            )
          } else {
            add_list.push(
              arr.find(item => {
                return item.id == nodeId
              }).id
            )
          }
        }
      })
      this.select_list = this.select_list.concat(add_list).filter(item => !del_list.includes(item))
      this.allList
        .filter(item => {
          return item.record.taskStatus != '已完成' && item.title != 'dropDiv'
        })
        .forEach(item => {
          if (this.$refs[item.id + '-select']) {
            if (this.select_list.some(i => i == item.id)) {
              this.$refs[item.id + '-select']._props.checked = true
              this.dropSelectChange({ target: { checked: true } }, item.record)
            } else {
              this.$refs[item.id + '-select']._props.checked = false
              this.dropSelectChange({ target: { checked: false } }, item.record)
            }
          }
        })
      this.taskIds = []
      this.allList
        .filter(item => {
          return item.record.taskStatus != '已完成' && item.title != 'dropDiv'
        })
        .forEach(item => {
          if (
            this.$refs[item.id + '-select'] &&
            this.$refs[item.id + '-select']._props.checked &&
            this.taskIds.every(i => i != item.record.id)
          ) {
            this.taskIds.push(item.record.id)
          } else if (
            this.$refs[item.id + '-select'] &&
            !this.$refs[item.id + '-select']._props.checked &&
            this.taskIds.some(i => i == item.record.id)
          ) {
            this.taskIds.splice(this.taskIds.indexOf(item.record.id), 1)
            let moveDiv = this.$refs[data.id]
            let parent = moveDiv.parentNode.parentNode.parentNode.parentNode
            if (!this.taskIds.length) {
              parent.getElementsByClassName('drop-list')[0].style.boxShadow = 'none'
            }
          }
        })
    },
    async dropSelectChange(e, data) {
      if (e.target.checked) {
        let moveDiv = this.$refs[data.id]
        let parent = moveDiv.parentNode.parentNode.parentNode.parentNode
        let addDiv = parent.getElementsByClassName('drop-list')[0] /* .parentNode.parentNode.parentNode */
        moveDiv.parentNode.id = data.id + '-parent'
        moveDiv.style.color = moveDiv.parentNode.style.color
        if (!addDiv.childNodes.length) {
          addDiv.style.boxShadow = '0 2px 8px rgb(0 0 0 / 15%)'
        }
        /* this.taskIds.push(data.id) */
        addDiv.appendChild(moveDiv)
      } else {
        let moveDiv = this.$refs[data.id]
        if (document.getElementById(data.id + '-parent'))
          document.getElementById(data.id + '-parent').appendChild(moveDiv)
      }
    },
    throttle(method, delay, duration) {
      var that = this
      var timer = this.timer
      var begin = new Date().getTime()
      return function() {
        var current = new Date().getTime()
        clearTimeout(timer)
        if (current - begin >= duration) {
          method()
          begin = current
        } else {
          that.timer = setTimeout(function() {
            method()
          }, delay)
        }
      }
    },
    collide(rect1, rect2) {
      const maxX = Math.max(rect1.x + rect1.width, rect2.x + rect2.width)
      const maxY = Math.max(rect1.y + rect1.height, rect2.y + rect2.height)
      const minX = Math.min(rect1.x, rect2.x)
      const minY = Math.min(rect1.y, rect2.y)
      if (maxX - minX <= rect1.width + rect2.width && maxY - minY <= rect1.height + rect2.height) {
        return true
      } else {
        return false
      }
    },
    fetchCalendarData(info, successCallback, failureCallback) {
      if (this.isDrag) {
        document.body.addEventListener('mousemove', this.handleMouseMove)
        document.body.addEventListener('mouseup', this.handleMouseUp)
        document.getElementsByClassName('mask')[0].style.width = '0'
        document.getElementsByClassName('mask')[0].style.height = '0'
        this.$nextTick(() => {
          this.isDrag = false
        })
      }
      this.throttle(
        () => {
          // 每次加载手动清空已加载元素
          if (this.$refs.fullCalendar) {
            this.$refs.fullCalendar.getApi().removeAllEvents()
          }
          let startDate = `${info.start.getFullYear()}-${info.start.getMonth() + 1}-${info.start.getDate()}`
          let endDate = `${info.end.getFullYear()}-${info.end.getMonth() + 1}-${info.end.getDate()}`
          let url = `/production/list_calendar_tasks?id=${this.$route.params.id}&startDate=${startDate}&endDate=${endDate}`
          getAction(url).then(({ success, data }) => {
            if (success) {
              data = [...data, ...this.tasks]
              //获取任务数据
              this.allList = []

              this.allList = data.map((one, index) => {
                return {
                  id: one.id,
                  title:
                    one.productName +
                    '-' +
                    parseFloat((one.chapterOrder - 0).toFixed(1)) +
                    '-' +
                    one.stageName +
                    '-' +
                    one.user.userName,
                  start: one.deadline,
                  allDay: true,
                  textColor:
                    one.taskStatus == '已完成'
                      ? 'green'
                      : one.taskStatus == '等待'
                      ? 'grey'
                      : one.taskStatus == '驳回'
                      ? 'orange'
                      : one.taskStatus == '可开始'
                      ? 'red'
                      : 'gray',
                  /* color: stageBackgroundColor[one.stageName] || stageBackgroundColor[one.stageId] || 'white', */
                  record: one,
                  eventOrder: index + 1
                  /* isCountent: true */
                }
              })
              this.filterList = []
              let dateArr = data.map(one => {
                return one.deadline.split(' ')[0] + ' 00:00:00'
              })
              dateArr = [...new Set(dateArr)]
              dateArr.forEach(item => {
                this.allList.push({
                  id: item,
                  title: 'dropDiv',
                  start: item,
                  eventOrder: 0,
                  allDay: true,
                  editable: true,
                  color: 'white',
                  textColor: 'gray',
                  record: {}
                })
              })
              const dom_box = document.querySelector('.box')
              this.box_screen_left = dom_box.getBoundingClientRect().left
              this.box_screen_top = dom_box.getBoundingClientRect().top
              console.log(this.allList, 'this.allList')
              successCallback(this.allList)
            }
          })
        },
        500,
        700
      )()
    },
    getBack(record) {
      let color = 'background-color:#f8f8f8'
      if (record) {
        if (record.taskStatus == '驳回') {
          color =
            record.deadline && record.deadline.slice(11, 16) < '18'
              ? 'background-color:rgb(255 225 225);border: 1px solid #ffa39e;color:orange;'
              : record.priority == 'HIGH'
              ? 'background-color:rgb(255 225 225);border: 1px solid #ffa39e;color:orange;'
              : record.finishStatus == 'DELAY'
              ? 'background-color:#fff9b7;color:orange;'
              : record.hasBeenReject
              ? 'background-color:rgb(255 229 199);color:orange;'
              : 'background-color:#f8f8f8;color:orange;'
        } else if (record.taskStatus == '已完成') {
          color =
            record.deadline && record.deadline.slice(11, 16) < '18' && record.finishStatus != 'DELAY'
              ? 'background-color:#f8f8f8;color:green;'
              : record.priority == 'HIGH' && record.finishStatus != 'DELAY'
              ? 'background-color:#f8f8f8;color:green;'
              : record.finishStatus == 'DELAY'
              ? 'background-color:#fff9b7;color:green;'
              : 'background-color:#f8f8f8;color:green;'
        } else if (record.taskStatus == '可开始') {
          color = record.hasBeenReject
            ? 'background-color:rgb(255 229 199);color:red;'
            : 'background-color:#f8f8f8;color:red;'
        }
        if (record.finishStatus == 'DELAY') {
          color = 'background-color:#fff9b7;'
        }
      }
      if (record.rejectTaskId && record.taskStatus != '驳回') {
        color = color + 'border: 1px solid orange;'
      }
      return color
    },
    todayClick() {
      this.calendarApi.today()
    },
    nextClick() {
      this.calendarApi.next()
    },
    prevClick() {
      this.calendarApi.prev()
    },
    dayClick() {
      this.calendarApi.changeView('dayGridDay')
    },
    weekClick() {
      this.calendarApi.changeView('dayGridWeek')
    },
    monthClick() {
      this.calendarApi.changeView('dayGridMonth')
    }
  }
}
</script>

<style scoped lang="less">
.box {
  user-select: none;
  position: relative;
  .mask {
    position: absolute;
    background: #409eff;
    opacity: 0.4;
    z-index: 999;
    transition: none !important;
  }
  .ant-checkbox {
    float: left;
  }
}
/deep/ .fc-toolbar-chunk:last-child {
  flex: 1 !important;
  display: flex !important;
  justify-content: end !important;
  align-items: center !important;
}
/deep/ .fc-today-button {
  order: 0 !important;
  margin-right: 0.75em;
}
.anticon-check-circle {
  font-size: 50px;
  position: absolute;
  right: 48px;
  bottom: 0;
  color: var(--theme-color);
  background: #fff;
  cursor: pointer;
  z-index: 999999;
  opacity: 0.5;
  transition: 0.3s all;
  &:hover {
    opacity: 1;
  }
}
/deep/ .fc .fc-view-harness {
  overflow-y: auto !important;
  height: calc(100vh - 195px) !important;
}
</style>
